.label {
  font-family: "Roboto-regular";
}

.goal {
  border-radius: 10px !important;
  margin-top: 10px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  padding: 10px !important;
  margin: "auto";
}

.goal-border {
  border: 1px solid #fff !important;
}

.goal-bg {
  background-color: #2cdee4 !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .goal {
    padding: 10px !important;
    width: 80px !important;
    height: 40px !important; /* padding: 5px !important; */
    /* margin-top: 10px !important; */
    /* width: 100% !important; */
    /* padding: 10px !important;
    margin-top: 5px;
    margin-left: 5px !important; 
    margin-right: 5px;
    border-radius: 10px !important; */
  }

  .goal-text {
    font-size: 10px !important;
  }
}
