.background {
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: "#70bg32";
  background: linear-gradient(158.89deg, #060606 22.06%, #2d333c 113.41%);
  background-repeat: no-repeat;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserratt-Bold"), url(fonts/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-bold-italic";
  src: local("Montserratt-bold-italic"), url(fonts/Montserrat-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-bold";
  src: local("Robotoo-bold"), url(fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Robotoo"), url(fonts/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-medium";
  src: local("Roboto-medium"), url(fonts/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-regular";
  src: local("Roboto-medium"), url(fonts/Roboto-Regular.ttf) format("truetype");
}


@font-face {
  font-family: "Roboto-italic";
  src: local("Roboto-italic"), url(fonts/Roboto-Italic.ttf) format("truetype");
}


@font-face {
  font-family: "Roboto-bold-italic";
  src: local("Roboto-bold-italic"), url(fonts/Roboto-BoldItalic.ttf) format("truetype");
}

section {
  padding: 50px 0;
}
