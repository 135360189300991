@import "variables";

section.contact {
  text-align: center;
  h2 {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 40px;
  }
  h3 {
    margin-top: 25px;
    color: #fff;
  }
  a {
    color: #fff;
  }
  ul.list-social {
    margin-bottom: 0;
    li {
      a {
        font-size: 30px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        display: block;
        color: white;
        border-radius: 100%;
        margin-inline: 8px;

        @media (min-width: 992px) {
          font-size: 40px;
          width: 80px;
          height: 80px;
          line-height: 80px;
        }
      }
      &.social-twitter {
        a {
          background-color: $brand-twitter;
          &:hover {
            background-color: darken($brand-twitter, 5%);
          }
        }
      }
      &.social-facebook {
        a {
          background-color: $brand-facebook;
          &:hover {
            background-color: darken($brand-facebook, 5%);
          }
        }
      }
      &.social-instagram {
        a {
          background-color: $brand-instagram;
          &:hover {
            background-color: darken($brand-instagram, 5%);
          }
        }
      }
      &.social-twitch {
        a {
          background-color: $brand-twitch;
          &:hover {
            background-color: darken($brand-twitch, 5%);
          }
        }
      }
      &.social-discord {
        a {
          background-color: $brand-discord;
          &:hover {
            background-color: darken($brand-discord, 5%);
          }
        }
      }
      &.social-youtube {
        a {
          background-color: $brand-youtube;
          &:hover {
            background-color: darken($brand-youtube, 5%);
          }
        }
      }
      &.social-tiktok {
        a {
          background-color: $brand-tiktok;
          &:hover {
            background-color: darken($brand-tiktok, 5%);
          }
        }
      }
      &.social-linkedin {
        a {
          background-color: $brand-linkedin;
          &:hover {
            background-color: darken($brand-linkedin, 5%);
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    h2 {
      font-size: 50px;
    }
  }
}
