.wrapper {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: "#70bg32";
  background: linear-gradient(158.89deg, #060606 22.06%, #2d333c 113.41%);
  background-repeat: no-repeat;
  border-radius: 10px;
  height: 100%;
}

.title {
  font-size: 25px;
  color: #fff;
  font-family: "Roboto-medium";
}

.label {
  color: #fff;
  font-size: 15px;
  font-family: "Roboto-regular";
}

.dropdown-menu.show {
  background-color: #4b4b4b;
}

.dropdown-menu {
  background-color: #fff;
}

.btn-primary {
  background-color: red;
}

.toto {
  /* font-family: "Montserrat-Bold"; */
}

.text-colored {
  color: #2cdee4;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 60px;
  height: 60px;
  padding: 6px 0px;
  border-radius: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.goalBtn {
  color: #2cdee4 !important;
  font-size: 30px !important;
}

.btn:hover,
.btn:focus {
  outline: none !important;
  border: none;
  box-shadow: none !important;
}
button:hover,
button:focus {
  outline: none !important;
  border: none;
  box-shadow: none !important;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.validate {
  border-width: 0;
  background-color: #2cdee4;
  font-size: 20px;
  border-radius: 3px;
  color: #000;
  font-family: "Roboto-medium";
  height: 50px;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*="col-"] {
  flex-grow: 1;
}

/* only for demo not required */
.box {
  /* border: 1px #666 solid; */
}

.row.display-flex [class*="col-"] {
  /* background-color: #cceeee; */
}

.modalBody {
  font-family: "Roboto";
  background-color: #1e2026;
}

.modalBtn {
  font-family: "Roboto";
  border-radius: 5px;
  width: 45%;
  height: 40px;
}

.modalTitle {
  font-family: "Roboto";
  font-size: 20px;
}

.required {
  color: #dc3545;
  margin-top: 0.25rem;
  font-size: 0.875em;
}

.form-check {
  padding-left: 35px !important;
}
