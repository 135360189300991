.label {
  font-family: "Roboto-regular";
}

.day {
  margin-top: 10px !important;
  width: 60px !important;
  height: 60px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  padding: 10px !important;
  border-radius: 5px !important;
  align-items: center !important;
}

.day-text {
  font-size: 14px !important;
}
/* .label {
    font-family: 'Roboto-medium';
} */

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .day {
    width: 35px !important;
    height: 35px !important;
  }

  .day-text {
    font-size: 10px !important;
  }
}
