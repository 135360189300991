.textColored {
  color: #2cdee4 !important;
}

.tableContainer {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: "#70bg32";
  background: linear-gradient(158.89deg, #060606 22.06%, #2d333c 113.41%);
  background-repeat: no-repeat;
  border-radius: 10px !important;
}

.tableContent {
  height: 220px;
  overflow: auto;
}

.wrapper {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: "#70bg32";
  background: linear-gradient(158.89deg, #060606 22.06%, #2d333c 113.41%);
  background-repeat: no-repeat;
  height: 100%;
}

.noMargin {
  margin-bottom: 0 !important;
}

.title-section {
  font-size: 12px;
}

.text-italic {
  font-family: "Montserrat-bold-italic";
}

.text-bold {
  font-family: "Roboto-bold";
}

.commercial-italic {
  font-family: "Roboto-italic";
}
