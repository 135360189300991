footer {
  padding: 25px 0;
  text-align: center;
  color: #666;
  p {
    font-size: 12px;
    margin: 0;
  }
  ul {
    margin-bottom: 0;
    li {
      a {
        font-size: 12px;
        color: #666;
        &:hover,
        &:focus,
        &:active,
        &.active {
          text-decoration: none;
        }
      }
    }
  }
}
