.joinUs {
  text-align: center;
  h3 {
    color: #fff;
  }

  .title {
    font-size: 18px;
    color: #fff;
    font-family: "Roboto-bold";
  }

  .label {
    color: #fff;
    font-size: 14px;
    font-family: "Roboto-regular";
  }

  .loginButton {
    border-width: 0;
    background-color: #2cdee4;
    font-size: 17px;
    border-radius: 3px;
    color: #000;
    height: 50px;
    font-family: "Roboto-bold";
    width: 80%;
  }

  .form-check {
    padding-left: 35px !important;
  }
}
