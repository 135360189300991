// Variables

// Gray and Brand Colors for use across theme

$theme-primary: #f3a000;
$theme-secondary: #212121;
$theme-tertiary: #926618;

$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee

$brand-twitter: #55acee;
$brand-facebook: #3b5998;
$brand-instagram: #3f729b;
$brand-twitch: #613fa0;
$brand-discord: #7289da;
$brand-youtube: #ff0000;
$brand-tiktok: #000000;
$brand-linkedin: #007bb6;
