.label {
  font-family: "Roboto-regular";
}

/* .label {
    font-family: 'Roboto-medium';
} */

.infoContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 200px;
  background-color: #282c3a;
  border-radius: 5px;
}

.textInfoss {
  /* font-family: 'RobotoBold', */
  font-size: 16px;
  color: #ffff;
}
