.navbar > .container-fluid {
  align-items: baseline !important;
}

.revolution-header {
  &.gym {
    font-size: 25px;
    font-family: "Roboto-bold" !important;
  }

 &.logout {
    font-family: "Roboto-regular";
  }
}
